import React, { Component } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"

import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md"

import "./Type.css"

class Type extends Component {
  static propTypes = {
    // parent
    types: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  handleClick = (type) => () => {
    this.props.onChange(type, "type")
  }

  render() {
    const { types, value } = this.props

    return (
      <div className="type">
        {types.map((type) => (
          <button key={type} type="button" onClick={this.handleClick(type)}>
            {type === value ? (
              <MdRadioButtonChecked color="white" size={24} />
            ) : (
              <MdRadioButtonUnchecked color="white" size={24} />
            )}
            <FormattedMessage id="option_type" values={{ type }} />
          </button>
        ))}
      </div>
    )
  }
}

export default Type
