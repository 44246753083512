import React, { Component } from "react"
import PropTypes from "prop-types"
import { injectIntl, intlShape } from "react-intl"
import { FormattedMessage } from "react-intl"

import { MdSend } from "react-icons/md"

import "./Discount.css"

import { db } from "../helpers/firebase"

class Discount extends Component {
  static propTypes = {
    // react-intl
    intl: intlShape.isRequired,
    // parent props
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onDiscount: PropTypes.func.isRequired,
  }

  state = {
    visible: false,
    checking: false,
    error: null,
    discount: null,
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.refs.input) {
      return
    }
    if (
      this.state.visible &&
      (!prevState.visible || (prevState.checking && !this.state.checking && !this.state.discount))
    ) {
      this.refs.input.focus()
    }
  }

  handleVisible = () => {
    this.setState({
      visible: true,
    })
  }

  handleChange = (event) => {
    this.props.onChange(event.target.value.trim().toUpperCase(), "discountCode")
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const { value, onDiscount } = this.props

    if (!value) {
      return
    }

    this.setState({
      checking: true,
      error: null,
    })

    db.ref("discounts/" + value)
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          const { percent } = snapshot.val()
          this.setState({
            checking: false,
            discount: percent,
          })
          onDiscount(percent)
        } else {
          this.setState({
            checking: false,
            error: "code_not_found",
          })
        }
      })
  }

  render() {
    const {
      intl: { formatMessage },
      value,
    } = this.props
    const { visible, checking, discount, error } = this.state

    if (!visible) {
      return (
        <div className="discount">
          <button onClick={this.handleVisible}>
            <FormattedMessage id="discount" />
          </button>
        </div>
      )
    }

    if (checking) {
      return (
        <div className="discount">
          <FormattedMessage id="discount_checking" />
        </div>
      )
    }

    if (discount) {
      return (
        <div className="discount">
          <FormattedMessage id="discount_percent" values={{ discount }} />
        </div>
      )
    }

    return (
      <div className="discount">
        <input
          ref="input"
          type="text"
          value={value}
          onChange={this.handleChange}
          placeholder={formatMessage({ id: "discount_placeholder" })}
        />
        <button type="button" onClick={this.handleSubmit}>
          <MdSend color="white" size={22} />
        </button>
        {error && (
          <div className="error">
            <FormattedMessage id={"error_" + error} />
          </div>
        )}
      </div>
    )
  }
}

export default injectIntl(Discount)
