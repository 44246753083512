import React, { Component } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"

import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md"

import "./Checkbox.css"

class Checkbox extends Component {
  static propTypes = {
    // parent
    name: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  handleClick = () => {
    const { name, value, onChange } = this.props
    onChange(!value, name)
  }

  render() {
    const { name, value } = this.props

    return (
      <div className="checkbox">
        <button type="button" onClick={this.handleClick}>
          {value ? <MdCheckBox color="white" size={24} /> : <MdCheckBoxOutlineBlank color="white" size={24} />}
          <FormattedMessage id={"form_" + name} />
        </button>
      </div>
    )
  }
}

export default Checkbox
