import React, { Component } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"

import { MdAddCircle, MdRemoveCircle } from "react-icons/md"

import "./People.css"

class People extends Component {
  static propTypes = {
    // parent
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    max: PropTypes.number.isRequired,
  }

  handleAdd = () => {
    const { onChange, value, max } = this.props
    onChange(Math.min(value + 1, max), "people")
  }

  handleRemove = () => {
    const { onChange, value } = this.props
    onChange(Math.max(value - 1, 1), "people")
  }

  render() {
    const { value } = this.props

    return (
      <div className="people">
        <FormattedMessage id="form_people" />
        <button type="button" onClick={this.handleRemove}>
          <MdRemoveCircle color="white" size={30} />
        </button>
        <span className="value">{value}</span>
        <button type="button" onClick={this.handleAdd}>
          <MdAddCircle color="white" size={30} />
        </button>
      </div>
    )
  }
}

export default People
