import React, { Component } from "react"
import PropTypes from "prop-types"
import { FormattedDate, FormattedMessage, injectIntl, intlShape } from "react-intl"
import { connect } from "react-redux"
import { Link, navigate } from "gatsby"
import { Redirect } from "@reach/router"
import { MdLock } from "react-icons/md"
import { loadStripe } from "@stripe/stripe-js"

import SEO from "../components/SEO"
import Header from "../components/Header"
import Input from "../components/Input"
import People from "../components/People"
import Type from "../components/Type"
import Checkbox from "../components/Checkbox"
import Discount from "../components/Discount"

import "./book.css"

import CONSTANTS from "../constants"

import api, { partner } from "../helpers/api"
import { format } from "../helpers/date"

class Book extends Component {
  static propTypes = {
    calendar: PropTypes.object,
    // react-intl
    intl: intlShape.isRequired,
    // redux
    date: PropTypes.object,
    types: PropTypes.array,
    data: PropTypes.object,
    error: PropTypes.string,
  }

  constructor(props) {
    super(props)
    const { date } = props

    this.stripePromise = loadStripe(process.env.GATSBY_STRIPE)

    if (!date) {
      this.state = {} // will redirect anyway
      return
    }

    // limit = today at 9pm
    this.limit = new Date()
    this.limit.setHours(CONSTANTS.maxBookingTime)
    this.limit.setMinutes(0)
    this.limit.setSeconds(0)

    const tomorrow = new Date()
    tomorrow.setDate(this.limit.getDate() + 1)

    const isTomorrow = date.toDateString() === tomorrow.toDateString()
    const isPast = format(date) <= format(this.limit)

    this.state = {
      paying: false,
      error: isPast || (isTomorrow && Date.now() > this.limit.getTime()) ? "too_late" : null,
      name: "",
      email: "",
      phone: "",
      people: 2,
      type: props.types[0],
      confirm: false,
      referral: "",
      discountCode: "",
      discount: null,
      isTomorrow,
    }
  }

  componentDidMount() {
    if (this.state.isTomorrow && !this.state.error) {
      this._timeout = setTimeout(this.setTooLate, this.limit.getTime() - Date.now())
    }
  }

  componentWillUnmount() {
    clearTimeout(this._timeout)
  }

  setTooLate = () => {
    this.setState({
      error: "too_late",
    })
  }

  handleInput = (value, name) => {
    this.setState({
      [name]: value,
    })
  }

  handleDiscount = (discount) => {
    this.setState({
      discount,
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { date } = this.props
    const { typeCapped, peopleCapped, deposit } = this.getCalculatedValues()

    this.setState({
      paying: true,
    })

    Promise.all([
      api.post("session", {
        lang: this.props.intl.locale,
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDate(),
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        type: typeCapped, // morning/afternoon/day
        people: peopleCapped, // between 1 and 8
        amount: deposit, // cents
        referral: this.state.referral,
        discountCode: this.state.discountCode,
      }),
      this.stripePromise,
    ])
      .then(([session, stripe]) => stripe.redirectToCheckout(session))
      .then(({ error }) => {
        if (error) {
          alert(error.message)
          this.setState({
            paying: false,
          })
        }
      })
      .catch((error) => {
        this.setState({
          paying: false,
          error: error.message,
        })
      })
  }

  handlePaying = () => {
    this.setState({
      paying: true,
    })
  }

  handleSuccess = () => {
    navigate("/thanks")
  }

  handleError = (error) => {
    this.setState({
      paying: false,
      error: error.message,
    })
  }

  getCalculatedValues = () => {
    const { types, data } = this.props
    const { type, people, discount } = this.state

    const typeCapped = types.indexOf(type) >= 0 ? type : types[0]

    const slot = data[typeCapped] || {}
    const spots = Math.max(0, CONSTANTS.maxPeople - (slot.booked || 0))

    const peopleCapped = Math.min(people, spots)
    const price = CONSTANTS.prices[typeCapped.indexOf("full") > 0 ? "full" : "classic"]
    let total = price * peopleCapped * 100

    if (discount) {
      total -= (total * discount) / 100
    }

    const deposit = Math.round(total * CONSTANTS.deposit)

    return {
      typeCapped,
      peopleCapped,
      deposit,
      spots,
      total,
    }
  }

  render() {
    const { date, types, data } = this.props

    if (!date) {
      return <Redirect to="/calendar/" noThrow />
    }

    const { paying, isTomorrow, name, email, phone, confirm, referral, discountCode, discount } = this.state
    const error = this.props.error || this.state.error

    let form
    if (error) {
      form = (
        <div className="msg big">
          <FormattedMessage id={"error_" + error} />
        </div>
      )
    } else if (paying) {
      form = (
        <div className="msg big">
          <FormattedMessage id="paying" />
        </div>
      )
    } else {
      const { typeCapped, peopleCapped, deposit, spots, total } = this.getCalculatedValues()

      let checkout
      if (confirm && name && phone && email && email.indexOf("@") > 0 && (!discountCode || discount)) {
        checkout = (
          <button type="submit" className="button">
            <MdLock />
            <FormattedMessage id="checkout_button" />
          </button>
        )
      } else {
        checkout = <FormattedMessage id="incomplete_form" />
      }

      const tomorrowAlert = isTomorrow && (
        <div className="msg">
          <FormattedMessage id="tomorrow_alert" />
        </div>
      )

      form = (
        <form onSubmit={this.handleSubmit}>
          {tomorrowAlert}
          <Input name="name" value={name} onChange={this.handleInput} required />
          <Input type="email" name="email" value={email} onChange={this.handleInput} required />
          <Input name="phone" value={phone} onChange={this.handleInput} required />
          <Type onChange={this.handleInput} types={types} value={typeCapped} />
          <People onChange={this.handleInput} value={peopleCapped} max={spots} />
          <Checkbox name="confirm" value={confirm} onChange={this.handleInput} />
          <Input name="referral" value={referral} onChange={this.handleInput} />
          <div className="price total">
            <FormattedMessage id="total" values={{ amount: total / 100 }} />
          </div>
          <Discount value={discountCode} onChange={this.handleInput} onDiscount={this.handleDiscount} />
          <div className="price deposit">
            <FormattedMessage id="deposit" values={{ amount: deposit / 100 }} />
          </div>
          <div className="msg tall">{checkout}</div>
          <div className="price remaining">
            <FormattedMessage id="remaining" values={{ amount: (total - deposit) / 100 }} />
          </div>
          <p>
            <Link to="/legal/">
              <FormattedMessage id="legal" />
            </Link>
          </p>
        </form>
      )
    }

    return (
      <div>
        <SEO />
        <Header bright>
          <FormattedMessage id="book_title" tagName="h1" />
        </Header>
        <main className="book river">
          <div className="event">
            <div className="month">
              <FormattedDate value={date} month="long" />
            </div>
            <div className="weekday">
              <FormattedDate value={date} weekday="long" />
            </div>
            <div className="day">
              <FormattedDate value={date} day="numeric" />
            </div>
          </div>
          {form}
        </main>
      </div>
    )
  }
}

const mapStateToProps = (state, { location }) => {
  if (!location.state) {
    return {
      date: null, // will redirect anyway
    }
  }
  const { date, monthStr, dayStr } = location.state
  const monthData = state.app.calendar[monthStr] || {}
  const data = monthData[dayStr] || {}

  const types = []
  let error

  const isClosed = (slot) => {
    if (slot) {
      if (slot.closed) {
        return true
      }
      if (slot.partner && slot.partner !== partner) {
        return true
      }
    }
    return false
  }

  const isFull = (slot) => {
    if (slot) {
      if (slot.booked >= CONSTANTS.maxPeople) {
        return true
      }
      if (isClosed(slot)) {
        return true
      }
    }
    return false
  }

  const isEmpty = (slot) => {
    if (slot) {
      if (slot.booked) {
        return false
      }
      if (isClosed(slot)) {
        return false
      }
    }
    return true
  }

  if (!isClosed(data.day)) {
    if (!isFull(data.morning)) {
      types.push("morning")
    }
    if (isEmpty(data.afternoon_full) && !isFull(data.afternoon)) {
      types.push("afternoon")
    }
    if (
      isEmpty(data.afternoon) &&
      !isFull(data.afternoon_full) &&
      date.getMonth() < CONSTANTS.maxMonthForFullAfternoon
    ) {
      types.push("afternoon_full")
    }
  }

  if (!types.length) {
    error = "full"

    if (isClosed(data.day)) {
      error = "closed"
    }
    if (isClosed(data.morning) && isClosed(data.afternoon)) {
      error = "closed"
    }
  }

  return { date, types, data, error }
}

export default connect(mapStateToProps)(injectIntl(Book))
